// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hypotheken-tsx": () => import("./../../src/pages/hypotheken.tsx" /* webpackChunkName: "component---src-pages-hypotheken-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-verzekeren-tsx": () => import("./../../src/pages/verzekeren.tsx" /* webpackChunkName: "component---src-pages-verzekeren-tsx" */),
  "component---src-templates-content-tsx": () => import("./../../src/templates/content.tsx" /* webpackChunkName: "component---src-templates-content-tsx" */)
}

